@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "inter";
  src: url("./resources/fonts/inter.ttf") format("truetype");
}

@font-face {
  font-family: "syne";
  src: url("./resources/fonts/syne.ttf") format("truetype");
}
